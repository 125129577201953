<template>
  <div>
    <b-row class="mx-0 score-board">
      <b-col class="p-2 text-white flex-col-gap-1 bg-main align-items-center"
        ><label class="text-r-14">Total net amount</label
        ><label class="text-m-14"
          >฿ {{ transaction.netPrice | numeral("0,0.00") }}</label
        >
      </b-col>
      <b-col class="p-2 text-white flex-col-gap-1 bg-main align-items-center"
        ><label class="text-r-14">Total point</label
        ><label class="text-m-14">{{
          transaction.point | numeral("0,0")
        }}</label>
      </b-col>
    </b-row>
    <div class="px-2 pb-2 detail-input-panel">
      <slot
        name="filter"
        :handleMobileSearchCustomer="handleSearchCustomer"
        :handleMobileSearch="handleSearchCustomer"
      ></slot>

      <div class="mt-2" v-if="user.member_id">
        <div class="d-flex justify-content-between mt-1">
          <label class="text-r-14">{{ user.member_id }}</label
          ><label class="pointer text-r-14">{{ user.point }} คะแนน</label>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <label class="text-r-14"
            >{{ user.first_name_th }} {{ user.last_name_th }}</label
          >
          <label class="text-r-14">
            <div v-if="$store.state.isCenterPoint == 0">
              {{ user.point + user.center_point }} คะแนนสาขา
            </div></label
          >
        </div>
        <div class="d-flex justify-content-between mt-1">
          <label class="text-r-14">{{ user.telephone || "-" }}</label>
          <label class="text-r-14 text-gray"
            ><u @click="editMember">แก้ไขข้อมูล</u></label
          >
        </div>
      </div>
    </div>
    <div class="p-2 detail-panel mt-2">
      <slot name="form"></slot>
    </div>
    <div class="p-2 detail-panel">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <label class="header-text font-color">รายการสินค้า</label>
        </div>
        <b-button
          :disabled="!user.member_id"
          class="radius-btn bg-main"
          @click="searchProduct"
          ><font-awesome-icon
            icon="plus"
            title="create-btn"
            class="mr-0 mr-sm-1"
          /><span class="ml-1 text-r-16">เพิ่มสินค้า</span></b-button
        >
      </div>
      <b-row class="mx-0 mt-2">
        <b-col cols="2" class="pl-0"
          ><span class="text-r-14">จำนวน</span></b-col
        >
        <b-col cols="7"><span class="text-r-14">รายการสินค้า</span></b-col>
        <b-col cols="3" class="pr-0"><span class="text-14">ราคา</span></b-col>
      </b-row>

      <div v-if="form.product.length > 0">
        <b-row
          class="mx-0 my-1 p-2 bg-white product-panel"
          v-for="(product, index) in form.product"
          :key="index"
          :class="{ selected: product.id == productSelected.id }"
        >
          <b-col
            cols="2"
            class="text-center pl-0 pr-0 border-product d-grid justify-content-center align-items-center"
            ><p @click="$emit('upProductQuantity', index)">
              <font-awesome-icon
                icon="plus-circle"
                title="up-btn"
                class="pointer"
                :class="{ 'text-gray': product.serial }"
              />
            </p>
            <p
              v-if="!clickQuantity || product.serial"
              @click="clickQuantity = true"
              class="my-0"
            >
              {{ product.quantity }}
            </p>
            <InputText
              v-else-if="clickQuantity && !product.serial"
              v-model="product.quantity"
              @blur="
                $emit('handleQuantity', index, product.quantity),
                  (clickQuantity = false)
              "
              @onEnter="
                $emit('handleQuantity', index, product.quantity),
                  (clickQuantity = false)
              "
              class="my-1 text-quantity d-flex justify-content-center"
              >{{ product.quantity }}</InputText
            >
            <p @click="$emit('downProductQuantity', index)">
              <font-awesome-icon
                icon="minus-circle"
                title="down-btn"
                class="pointer"
              /></p
          ></b-col>

          <b-col
            cols="7"
            class="px-2 pointer"
            @click="openSlideEditProduct(index)"
          >
            <p class="text-r-16">{{ product.barcode }}</p>
            <p class="text-r-14">{{ product.name }}</p>
            <p
              v-if="!product.serial && product.is_serial"
              class="product-info text-l-14"
            >
              Serial Number :
              <span class="text-link" @click.stop="selectSerial(product, index)"
                >Add Serial</span
              >
            </p>
            <div
              v-else-if="product.serial && product.is_serial"
              class="product-info text-nowrap text-l-14 w-fit"
            >
              Serial Number :
              <span
                class="text-link serial relative"
                @click.stop="selectSerial(product, index)"
                >{{ product.serial }}</span
              >
              <font-awesome-icon
                icon="trash-alt"
                title="delete-btn"
                class="pointer text-danger mt-1 ml-2 serial"
                @click.stop="deleteSerial(product, index)"
              />
            </div>
            <p class="text-l-14 text-gray" v-if="product.promotion_detail">
              {{ product.promotion_detail.textPromotion }}
            </p>
          </b-col>

          <b-col
            cols="2"
            class="pr-0 pointer"
            @click="openSlideEditProduct(index)"
          >
            <p v-if="product.promotion_detail">
              {{ product.promotion_detail.net_price | numeral("0,0.00") }}
            </p>

            <p v-else-if="product.sap_discount.id">
              <del>
                {{ product.price | numeral("0,0.00") }}
              </del>
            </p>
            <p v-else>
              {{
                product.sap_discount.id
                  ? product.priceSap
                  : product.price | numeral("0,0.00")
              }}
            </p>
          </b-col>
        </b-row>
      </div>
      <div v-else class="p-2 text-center text-general no-product mt-2">
        <p>กรุณาเพิ่มสินค้า</p>
      </div>
    </div>
    <div class="px-2 py-2 detail-panel">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <label class="header-text">คูปองและส่วนลดท้ายบิล</label>
        <label class="pointer text-gray text-r-16"
          ><u
            @click="openCampaign()"
            v-if="user.user_guid && form.product.length"
            >เพิ่มคูปอง</u
          ></label
        >
      </div>
      <div
        class="mb-2"
        v-for="(promotion, index) in form.promotion"
        :key="index"
      >
        <div class="d-flex justify-content-between">
          <div class="flex-gap-4">
            <div class="d-flex">
              <font-awesome-icon
                icon="trash-alt"
                title="delete-btn"
                class="pointer text-danger mt-1"
                @click="$emit('deleteCoupon', promotion)"
              />
            </div>
            <div>
              <p class="text-m-16">{{ promotion.name }}</p>
            </div>
          </div>
          <div class="flex-gap-1">
            <span
              v-if="promotion.point >= 0 && promotion.promotion_value == 0"
              class="text-m-16"
            >
              ได้รับ {{ promotion.point | numeral("0,0") }}</span
            >
            <span
              v-else-if="promotion.custom_header_discount"
              class="text-m-16 text-danger"
            >
              {{ promotion.custom_header_discount > 0 ? "-" : ""
              }}{{ promotion.custom_header_discount | numeral("0,0.00") }}</span
            >

            <span v-else class="text-m-16 text-danger"
              >{{ promotion.promotion_value > 0 ? "-" : ""
              }}{{ `${promotion.promotion_value}` | numeral("0,0.00") }}</span
            >

            <span
              class="text-m-16"
              :class="{
                'text-danger':
                  !(promotion.point >= 0 && promotion.promotion_value == 0) &&
                  promotion.discount_type !== 'point' &&
                  promotion.promotion_discount_type_id !== 3,
              }"
            >
              {{
                promotion.point >= 0 && promotion.promotion_value == 0
                  ? "แต้ม"
                  : promotion.promotion_discount_type_id == 1 ||
                    promotion.discount_type == "percent"
                  ? "%"
                  : promotion.promotion_discount_type_id == 2 ||
                    promotion.discount_type == "amount"
                  ? "บาท"
                  : promotion.promotion_discount_type_id == 3 ||
                    promotion.discount_type == "point"
                  ? "แต้ม"
                  : ""
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 p-2 detail-panel detail-summary header-text font-color">
      <div class="d-flex justify-content-between">
        <span>ราคารวม</span>
        <span>{{ transaction.price | numeral("0,0.00") }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>ส่วนลดรวม</span>
        <span class="text-danger">
          <span class="text-danger" v-if="transaction.discount > 0">-</span
          >{{ transaction.discount | numeral("0,0.00") }}</span
        >
      </div>
      <div class="d-flex justify-content-between">
        <span>ราคาสุทธิ</span>
        <span>{{ transaction.netPrice | numeral("0,0.00") }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>คะแนนที่ได้รับ</span>
        <span>{{ transaction.point | numeral("0,0") }} คะแนน</span>
      </div>
    </div>
    <div class="mobile-footer">
      <div class="d-flex justify-content-between">
        <div>
          <div class="text-r-14 text-gray">ราคาสุทธิ</div>
          <div class="text-m-20">
            {{ transaction.netPrice | numeral("0,0.00") }}
          </div>
        </div>
        <b-button class="radius-btn" @click="submit">
          <span class="text-r-16">
            ยืนยัน ({{ transaction.point | numeral("0,0") }} คะแนน)
          </span>
        </b-button>
      </div>
    </div>

    <SlideCustomerList
      ref="SlideCustomerList"
      @searchNotFound="handleSearchCustomer('isNotFound')"
      @setUserSelect="$emit('setUserSelect', $event)"
    />

    <SlideCustomer ref="SlideCustomer" @success="$emit('getUserDetail')" />

    <SlideProduct
      ref="SlideProduct"
      :form="form"
      :promotionList="promotionList"
      @addCredit="$emit('addCredit', $event)"
      @addProductList="$emit('addProductList', $event)"
      @promotionListformProduct="getPromotionListformProduct"
      @itemDiscountFromSelect="geIitemDiscountFromSelect"
    />
    <SlideDiscount
      ref="SlideDiscount"
      :headerDiscountOption="headerDiscountOption"
      :promotionList="promotionList"
      :itemDiscountList="itemDiscountList"
      :headerDiscountItems="headerDiscountItems"
      :isCampaign="isCampaign"
      @addCoupon="$emit('addCoupon', $event, false)"
      @addItemDiscount="$emit('addItemDiscount', $event, selectProductIndex)"
      @addSapDiscount="$emit('addSapDiscount', $event, selectProductIndex)"
      :promotionListformProduct="promotionListformProduct"
      :selectProductIndex="selectProductIndex"
      :itemDiscountFromSelect="itemDiscountFromSelect"
      :form="form.product"
    />
    <SlideSelectSerial
      ref="SlideSelectSerial"
      @selectSerial="handleSelectSerial"
      @unselectItem="handleSelectSerial"
      :branchId="branchId"
      :productList="form.product"
    />
  </div>
</template>

<script>
import SlideCustomerList from "./components/SlideCustomerList.vue";
import SlideCustomer from "./components/SlideCustomer.vue";
import SlideProduct from "./components/SlideProduct.vue";
import SlideDiscount from "./components/SlideDiscount.vue";
import InputText from "@/components/inputs/InputText";
import SlideSelectSerial from "./components/SlideSelectSerial.vue";

import { mapGetters } from "vuex";
export default {
  name: "HomeMobile",
  components: {
    SlideSelectSerial,
    SlideCustomerList,
    SlideCustomer,
    SlideProduct,
    SlideDiscount,
    InputText,
  },
  props: {
    user: {
      required: true,
      type: Object,
    },
    form: {
      required: true,
      type: Object,
    },
    transaction: {
      required: true,
      type: Object,
    },
    filter: {
      required: true,
      type: Object,
    },
    headerDiscountOption: {
      type: Array,
      isRequired: true,
    },
    promotionList: {
      type: Array,
      isRequired: true,
    },
    itemDiscountList: {
      type: Array,
      isRequired: true,
    },
    selecedHeaderDiscountIdList: {
      type: Array,
      isRequired: true,
    },
    productSelected: {
      required: true,
      type: Object,
    },
    headerDiscountItems: {
      type: Array,
      isRequired: true,
    },
    branchId: {
      isRequired: false,
    },
  },
  data() {
    return {
      clickQuantity: false,
      selectProductIndex: 0,
      promotionListformProduct: [],
      itemDiscountFromSelect: [],
      isLoad: false,
      isCampaign: false,
      localFilter: {
        search: "",
        saleId: "",
        invoiceNo: "",
        member: {
          search: "",
        },
      },
    };
  },
  watch: {
    filter: {
      handler() {
        this.localFilter = this.filter;
      },
      immediate: true,
    },
  },
  computed: {
    selectedCouponName() {
      return "";
    },
  },

  async created() {},

  methods: {
    openCampaign() {
      this.isCampaign = true;
      this.$refs.SlideDiscount.show(
        this.user,
        this.selecedHeaderDiscountIdList
      );
    },
    async openSlideEditProduct(index) {
      await this.$store.dispatch("setMainLoading", true);
      this.isCampaign = false;
      this.$emit("setSelectProduct", {
        index: index,
        ...this.form.product[index],
      });
      this.selectProductIndex = index;

      await this.$refs.SlideProduct.getDiscount(
        index,
        this.form.product[index].id,
        this.user.user_guid
      );

      this.$refs.SlideDiscount.show(
        this.user,
        this.selecedHeaderDiscountIdList
      );
      await this.$store.dispatch("setMainLoading", false);
    },
    async editMember() {
      await this.$emit("getUserDetail");
      let user = { ...this.user };
      user.firstname = this.user.first_name_th;
      user.lastname = this.user.last_name_th;
      user.firstname_en = this.user.first_name_en;
      user.lastname_en = this.user.last_name_en;
      user.birthday = this.user.birthday;
      user.email = this.user.email ? this.user.email.trim() : "";
      const customField = await this.$axios(
        `${this.$baseUrl}/customer/GetUserRegisterCustomFieldList/${this.user.user_guid}`
      );
      user.custom_field = customField.detail;
      this.$refs.SlideCustomer.show(user);
    },
    async searchProduct() {
      if (this.user.user_guid) {
        if (this.filter.search.length) {
          const list = await this.$refs.SlideProduct.searchBeforeShow(
            this.user,
            this.filter.search
          );
          if (list.length) {
            this.$emit("addProduct", list[0]);
          } else {
            this.$refs.SlideProduct.show(this.user, this.filter.search);
          }
        } else {
          this.$refs.SlideProduct.show(this.user, this.filter.search);
        }
      } else {
        this.warningAlert("กรุณาใส่ข้อมูลลูกค้า");
      }
    },
    handleCoupon() {},
    submit() {
      this.$emit("submit");
    },

    async getList() {
      let payload = {
        page: 1,
        branch_id: this.branchId,
        datetime:
          this.dateTimeFix || this.$moment().format("YYYY-MM-DDTHH:mm:ss"),
        search: this.filter.member.search,
        skip: 0,
        take: 5,
      };

      await this.axios
        .post(`${this.$baseUrl}/customer/searchuser`, payload)
        .then(async (data) => {
          if (data.result == 1) {
            this.customerList = data.detail;
          }
        });
    },
    async handleSearchCustomer(notFound) {
      if (!this.isLoad) {
        await this.$store.dispatch("setMainLoading", true);
        this.isLoad = true;
        await this.getList();
        if (
          (this.customerList.length == 0 && this.filter.member.search) ||
          notFound === "isNotFound"
        ) {
          this.$router.push("/register");
        } else {
          this.$refs.SlideCustomerList.show(this.filter.member.search);
        }
        this.customerList = [];
        await this.$store.dispatch("setMainLoading", false);
        this.isLoad = false;
      }
    },
    setUserSelect() {},
    getPromotionListformProduct(list, index) {
      this.promotionListformProduct[index] = list;
    },
    geIitemDiscountFromSelect(list, index) {
      this.itemDiscountFromSelect[index] = list;
    },

    selectSerial(product, index) {
      this.$refs.SlideSelectSerial.show(product, index);
    },
    deleteSerial(product, index) {
      this.$emit("handleSelectSerial", null, product.id, index);
    },
    handleSelectSerial(serial, productId, index) {
      this.$emit("handleSelectSerial", serial, productId, index);
      this.$refs.SlideSelectSerial.hide();
    },
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      mainLoading: "getMainLoading",
    }),
    ...mapGetters(["isLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.mobile-footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  box-shadow: 0 -10px 20px -5px #737373bf;
  button {
    min-width: 150px;
  }
}

.detail-summary {
  padding-bottom: 85px !important;
}

.no-product {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bababa2e;
  border-radius: 5px;
}

.selected {
  background-color: #bababa2e !important;
}

.text-quantity {
  ::v-deep .custom-input {
    text-align: center;
    width: 70%;
    font-size: 16px;
    padding: 0 !important;
  }

  ::v-deep .warp-input {
    display: flex;
    justify-content: center;
  }
}

.text-link {
  text-decoration: underline;
}

.d-grid {
  display: grid;
}

.serial {
  position: relative;
  z-index: 100;
}

.w-fit {
  width: fit-content !important;
}
</style>
